import React from "react"
import PropType from "prop-types"
import Helmet from "react-helmet"
import Img from "gatsby-image"
import { graphql, Link } from "gatsby"
import Booking from "../components/Booking.tsx"
import BookingWidget from "../components/BookingWidget.tsx"
import Title from "../components/Title.tsx"
import Sticky from "../components/Sticky.tsx"
import Layout from "../components/layout"
import SimpleMap from "../components/SimpleMap"
import { isMobile } from "react-device-detect"
import Content from "../components/Content.tsx"
import Grid from "react-css-grid"
import classNames from "classnames"
import { CssGrid } from "../components/CssGrid"
import Seo from "../components/Seo.js"

import { BOOKING_CONFIG } from "../constants"

let MAP_CENTER, ZOOM

// const stripHtml = (html) => {
//   if (typeof window !== 'undefined') {
//     const doc = new DOMParser().parseFromString(html, 'text/html');
//     return doc.body.textContent || '';
//   }
//   return html;
// };
const PostTemplate = props => {
  const {
    data: { wordpressWpContact: post },
  } = props

  if (post.wordpress_id === 234) {
    MAP_CENTER = { lat: 22.2894718, lng: 114.1642068 }
    ZOOM = 13
  } else {
    MAP_CENTER = { lat: -26.5245411, lng: 135.1763979 }
    ZOOM = 4
  }

  const postClasses = "internal-page page-" + post.wordpress_id

  return (
    <Layout>
      <Helmet link={[{ rel: "canonical", content: post.yoast.canonical }]} />
      <Seo
        title={post.yoast.title || post.acf.title_seo || post.title}
        description={
          post.yoast.metadesc || post.acf.paragraph_seo || post.excerpt
        }
        pathname={post.slug}
        article
      />
      <CssGrid className={postClasses}>
        <article>
          <div className="sticky">
            <div className="wp-block-columns has-2-columns sc-bdVaJa kHPcJw">
              <div
                className="wp-block-column"
                dangerouslySetInnerHTML={{ __html: post.content }}
              />
              <SimpleMap
                zoom={ZOOM}
                center={MAP_CENTER}
                markers={[
                  {
                    type: "ovolo",
                    title: "Ovolo Group Head Office",
                    position: { lat: -33.86894, lng: 151.220189 },
                  },
                  {
                    type: "blue",
                    title: "Ovolo Wooloomoolloo",
                    position: { lat: -33.86894, lng: 151.220189 },
                  },
                  {
                    type: "brown",
                    title: "Ovolo 1888 Darling Harbour",
                    position: { lat: -33.872628, lng: 151.197057 },
                  },
                  {
                    type: "magenta",
                    title: "Ovolo Laneways",
                    position: { lat: -37.81059, lng: 144.971956 },
                  },
                  {
                    type: "green",
                    title: "Ovolo Inchcolm",
                    position: { lat: -27.46447, lng: 153.026882 },
                  },
                  {
                    type: "pink",
                    title: "Ovolo The Valley",
                    position: { lat: -27.452377, lng: 153.039291 },
                  },
                  {
                    type: "orange",
                    title: "Ovolo Nishi",
                    position: { lat: -35.284816, lng: 149.12276 },
                  },
                  {
                    type: "ovolo",
                    title: "Ovolo Group Head Office",
                    position: { lat: 22.2795652, lng: 114.1521531 },
                  },
                  {
                    type: "brown2",
                    title: "Ovolo Central",
                    position: { lat: 22.280249, lng: 114.154622 },
                  },
                  {
                    type: "orange2",
                    title: "Ovolo Southside",
                    position: { lat: 22.249604, lng: 114.169098 },
                  },
                  {
                    type: "red",
                    title: "Mojo Nomad Aberdeen",
                    position: { lat: 22.248766, lng: 114.149101 },
                  },
                  {
                    type: "brown",
                    title: "The Sheung Wan",
                    position: { lat: 22.284624, lng: 114.150587 },
                  },
                ]}
              />
            </div>
          </div>
        </article>
      </CssGrid>
    </Layout>
  )
}
PostTemplate.propTypes = {
  data: PropType.shape({}).isRequired,
}
export default PostTemplate

export const pageQuery = graphql`
  query($id: String!) {
    wordpressWpContact(id: { eq: $id }) {
      wordpress_id
      title
      slug
      yoast {
        title
        metadesc
        canonical
      }
      acf {
        title_seo
        subtitle_seo
        paragraph_seo
      }
      content
      date(formatString: "DD, MMM YYYY")
      featured_media {
        source_url
        localFile {
          childImageSharp {
            id
            sizes(maxWidth: 1000) {
              ...GatsbyImageSharpSizes
            }
          }
        }
      }
      author {
        name
        description
      }
    }
  }
`
