import {
  GoogleMap,
  InfoWindow,
  LoadScript,
  Marker,
} from "@react-google-maps/api"
import React, { FunctionComponent, useRef, useState } from "react"
import iconBlue from "../images/map/blue.svg"
import iconBrown from "../images/map/brown.svg"
import iconGreen from "../images/map/green.svg"
import iconMagenta from "../images/map/magenta.svg"
import iconMojo from "../images/map/mojo.svg"
import iconOrange from "../images/map/orange.svg"
import iconOvolo from "../images/map/ovolo.svg"
import iconRed from "../images/map/red.svg"
import iconPink from "../images/map/pink.svg"
import iconOrange2 from "../images/map/orange2.svg"
import iconBrown2 from "../images/map/brown2.svg"
import { IDirectionsAddress, IDirectionsMarker } from "../types"
import "./SimpleMap.scss"

const MARKER_ICON = {
  blue: iconBlue,
  brown: iconBrown,
  green: iconGreen,
  magenta: iconMagenta,
  mojo: iconMojo,
  orange: iconOrange,
  ovolo: iconOvolo,
  red: iconRed,
  pink: iconPink,
  orange2: iconOrange2,
  brown2: iconBrown2,
}
const MY_STYLE = [
  { elementType: "geometry", stylers: [{ color: "#f5f5f5" }] },
  { elementType: "labels.icon", stylers: [{ visibility: "off" }] },
  { elementType: "labels.text.fill", stylers: [{ color: "#616161" }] },
  { elementType: "labels.text.stroke", stylers: [{ color: "#f5f5f5" }] },
  {
    featureType: "administrative.land_parcel",
    elementType: "labels.text.fill",
    stylers: [{ color: "#bdbdbd" }],
  },
  {
    featureType: "poi",
    elementType: "geometry",
    stylers: [{ color: "#eeeeee" }],
  },
  {
    featureType: "poi",
    elementType: "labels.text.fill",
    stylers: [{ color: "#757575" }],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [{ color: "#e5e5e5" }],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.fill",
    stylers: [{ color: "#9e9e9e" }],
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [{ color: "#ffffff" }],
  },
  {
    featureType: "road.arterial",
    elementType: "labels.text.fill",
    stylers: [{ color: "#757575" }],
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [{ color: "#dadada" }],
  },
  {
    featureType: "road.highway",
    elementType: "labels.text.fill",
    stylers: [{ color: "#616161" }],
  },
  {
    featureType: "road.local",
    elementType: "labels.text.fill",
    stylers: [{ color: "#9e9e9e" }],
  },
  {
    featureType: "transit.line",
    elementType: "geometry",
    stylers: [{ color: "#e5e5e5" }],
  },
  {
    featureType: "transit.station",
    elementType: "geometry",
    stylers: [{ color: "#eeeeee" }],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [{ color: "#c9c9c9" }],
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [{ color: "#9e9e9e" }],
  },
]

export const SimpleMap: FunctionComponent<{
  markers?: IDirectionsMarker[]
  center?: google.maps.LatLngLiteral
  zoom?: number
  addresses: IDirectionsAddress[]
}> = ({ markers = [], zoom = 15, center, addresses = [] }) => {
  const [mapMarkers, setMapMarkers] = useState<IDirectionsMarker[]>(markers)

  const mainAddress = addresses.find(item => !!item.main) || addresses[0]
  const from = useRef<HTMLSelectElement>(null)
  const fromInput = useRef<HTMLInputElement>(null)
  const toInput = useRef<HTMLInputElement>(null)

  const handleMarkerToggle = (targetMarker: IDirectionsMarker) => {
    setMapMarkers(
      mapMarkers.map(marker => {
        if (marker === targetMarker) {
          return {
            ...marker,
            showInfo: !marker.showInfo,
          }
        }
        return { ...marker, showInfo: false }
      })
    )
  }
  return (
    <div className="wp-block-column map">
      <LoadScript
        id="script-loader"
        googleMapsApiKey="AIzaSyAzUJA_O6UbE8cvnhPfYI8zf6kgYHWesyM"
      >
        <GoogleMap
          id="ovolo-map"
          mapContainerStyle={{
            height: "100vh",
            width: "100vh",
            position: "fixed",
            overflow: "hidden",
          }}
          zoom={zoom}
          center={center || mapMarkers[0].position}
          options={{
            styles: MY_STYLE,
          }}
        >
          {mapMarkers.map((item, index) => (
            <Marker
              key={index}
              position={item.position}
              icon={MARKER_ICON[item.type]}
              onClick={() => item.title && handleMarkerToggle(item)}
            >
              {item.showInfo && item.title && (
                <InfoWindow
                  options={{ pixelOffset: new google.maps.Size(0, -25) }}
                  position={{
                    lat: item.position.lat,
                    lng: item.position.lng,
                  }}
                  onCloseClick={() => handleMarkerToggle(item)}
                >
                  <h4>{item.title}</h4>
                </InfoWindow>
              )}
            </Marker>
          ))}
        </GoogleMap>
      </LoadScript>
    </div>
  )
}

export default SimpleMap
